* {
  box-sizing: border-box;
}

body, html {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

.av2-container {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-column-gap: 24px;
}

.p-accordion .p-accordion-content {
  padding: 24px;
}

.multiLevelAccordionTab > .p-accordion-tab > .p-toggleable-content > .p-accordion-content {
  padding: 0 !important;
}

@media only screen and (max-width: 480px) {
  .av2-container {
    grid-template-columns: repeat(4, [col-start] 1fr);
  }
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .av2-container {
    grid-template-columns: repeat(8, [col-start] 1fr);
    grid-column-gap: 18px;
  }
}
.av2-text-small-1 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-2 {
  font-family: "Inter-Regular";
  font-size: 11px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-3 {
  font-family: "Inter-Regular";
  font-size: 8px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-1, .av2-card-details-text-body-small, .av2-card-details-text-body, .av2-card-list-text-body-small, .av2-card-list-text-body, .av2-input-text-right .av2-input-text-right-label, .av2-card-label, .av2-dropdown, textarea.av2-text-area, .av2-text-input, .av2-form-label, .av2-chart-card .av2-chart-amount, .av2-simple-chart-card .av2-simple-chart-subvalue, .av2-info-bar, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-subtitle, .av2-badge, .av2-link-button, .av2-button, .av2-radio-button-horizontal .p-radiobutton-label {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-1, .av2-card-details-text-title-small, .av2-card-details-text-title, .av2-card-list-text-title-small, .av2-card-list-text-title, .av2-form-field label, .av2-chart-card .av2-chart-count, .av2-chart-card .av2-chart-title, .av2-chart-card .av2-chart-card-title, .av2-map-control-point-list-row .av2-map-control-point-list-item span.point, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-title, .av2-tag-control-point-item .av2-tag-control-point-item-title, .av2-filter-badge, .av2-info-dialog-container .av2-info-dialog-message {
  font-family: "Inter-SemiBold";
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-2 {
  font-family: "Inter-SemiBold";
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-3 {
  font-family: "Inter-SemiBold";
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  text-align: center;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-4 {
  font-family: "Inter-Bold";
  font-size: 8px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-5, .av2-card-details-text-title-pdf {
  font-family: "Inter-Bold";
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-6 {
  font-family: "Inter-Bold";
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-2, .av2-table-selection-buttons-container, a.av2-form-helper, .av2-simple-chart-card .av2-simple-chart-title, .av2-tag-control-point-item .av2-tag-control-point-item-subtitle {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.26px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-3, .av2-simple-chart-card .av2-simple-chart-value {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 22px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-4, .av2-card-details-text-body-pdf {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-1 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-2 {
  font-family: "Inter-Regular";
  font-size: 11px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-3 {
  font-family: "Inter-Regular";
  font-size: 8px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-1, .av2-card-details-text-body-small, .av2-card-details-text-body, .av2-card-list-text-body-small, .av2-card-list-text-body, .av2-input-text-right .av2-input-text-right-label, .av2-card-label, .av2-dropdown, textarea.av2-text-area, .av2-text-input, .av2-form-label, .av2-chart-card .av2-chart-amount, .av2-simple-chart-card .av2-simple-chart-subvalue, .av2-info-bar, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-subtitle, .av2-badge, .av2-link-button, .av2-button, .av2-radio-button-horizontal .p-radiobutton-label {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-1, .av2-card-details-text-title-small, .av2-card-details-text-title, .av2-card-list-text-title-small, .av2-card-list-text-title, .av2-form-field label, .av2-chart-card .av2-chart-count, .av2-chart-card .av2-chart-title, .av2-chart-card .av2-chart-card-title, .av2-map-control-point-list-row .av2-map-control-point-list-item span.point, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-title, .av2-tag-control-point-item .av2-tag-control-point-item-title, .av2-filter-badge, .av2-info-dialog-container .av2-info-dialog-message {
  font-family: "Inter-SemiBold";
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-2 {
  font-family: "Inter-SemiBold";
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-3 {
  font-family: "Inter-SemiBold";
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  text-align: center;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-4 {
  font-family: "Inter-Bold";
  font-size: 8px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-5, .av2-card-details-text-title-pdf {
  font-family: "Inter-Bold";
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-6 {
  font-family: "Inter-Bold";
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-2, .av2-table-selection-buttons-container, a.av2-form-helper, .av2-simple-chart-card .av2-simple-chart-title, .av2-tag-control-point-item .av2-tag-control-point-item-subtitle {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.26px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-3, .av2-simple-chart-card .av2-simple-chart-value {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 22px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-4, .av2-card-details-text-body-pdf {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-container {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-column-gap: 24px;
}

.p-accordion .p-accordion-content {
  padding: 24px;
}

.multiLevelAccordionTab > .p-accordion-tab > .p-toggleable-content > .p-accordion-content {
  padding: 0 !important;
}

@media only screen and (max-width: 480px) {
  .av2-container {
    grid-template-columns: repeat(4, [col-start] 1fr);
  }
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .av2-container {
    grid-template-columns: repeat(8, [col-start] 1fr);
    grid-column-gap: 18px;
  }
}
.av2-info-dialog-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 232px;
  position: relative;
  padding: 0;
}
.av2-info-dialog-container .av2-info-dialog-icon {
  width: 64px;
  height: 64px;
}
.av2-info-dialog-container .av2-info-dialog-message {
  margin-top: 36px;
  font-size: 18px;
  letter-spacing: -0.72px;
}

.av2-info-dialog-footer {
  margin-top: 24px;
  width: 232px;
  display: flex;
  flex-flow: column;
  row-gap: 12px;
}

.av2-text-small-1 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-2 {
  font-family: "Inter-Regular";
  font-size: 11px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-3 {
  font-family: "Inter-Regular";
  font-size: 8px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-1, .av2-card-details-text-body-small, .av2-card-details-text-body, .av2-card-list-text-body-small, .av2-card-list-text-body, .av2-input-text-right .av2-input-text-right-label, .av2-card-label, .av2-dropdown, textarea.av2-text-area, .av2-text-input, .av2-form-label, .av2-chart-card .av2-chart-amount, .av2-simple-chart-card .av2-simple-chart-subvalue, .av2-info-bar, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-subtitle, .av2-badge, .av2-link-button, .av2-button, .av2-radio-button-horizontal .p-radiobutton-label {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-1, .av2-card-details-text-title-small, .av2-card-details-text-title, .av2-card-list-text-title-small, .av2-card-list-text-title, .av2-form-field label, .av2-chart-card .av2-chart-count, .av2-chart-card .av2-chart-title, .av2-chart-card .av2-chart-card-title, .av2-map-control-point-list-row .av2-map-control-point-list-item span.point, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-title, .av2-tag-control-point-item .av2-tag-control-point-item-title, .av2-filter-badge, .av2-info-dialog-container .av2-info-dialog-message {
  font-family: "Inter-SemiBold";
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-2 {
  font-family: "Inter-SemiBold";
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-3 {
  font-family: "Inter-SemiBold";
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  text-align: center;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-4 {
  font-family: "Inter-Bold";
  font-size: 8px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-5, .av2-card-details-text-title-pdf {
  font-family: "Inter-Bold";
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-6 {
  font-family: "Inter-Bold";
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-2, .av2-table-selection-buttons-container, a.av2-form-helper, .av2-simple-chart-card .av2-simple-chart-title, .av2-tag-control-point-item .av2-tag-control-point-item-subtitle {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.26px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-3, .av2-simple-chart-card .av2-simple-chart-value {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 22px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-4, .av2-card-details-text-body-pdf {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-container {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-column-gap: 24px;
}

.p-accordion .p-accordion-content {
  padding: 24px;
}

.multiLevelAccordionTab > .p-accordion-tab > .p-toggleable-content > .p-accordion-content {
  padding: 0 !important;
}

@media only screen and (max-width: 480px) {
  .av2-container {
    grid-template-columns: repeat(4, [col-start] 1fr);
  }
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .av2-container {
    grid-template-columns: repeat(8, [col-start] 1fr);
    grid-column-gap: 18px;
  }
}
.av2-radio-button-horizontal .p-radiobutton {
  display: none;
}
.av2-radio-button-horizontal .p-radiobutton-label {
  font-weight: 600;
  height: 38px;
  padding: 10px 16px;
  border-radius: 4px;
  text-align: center;
  margin: 0;
  width: 100%;
  cursor: pointer;
}

.av2-text-small-1 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-2 {
  font-family: "Inter-Regular";
  font-size: 11px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-3 {
  font-family: "Inter-Regular";
  font-size: 8px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-1, .av2-card-details-text-body-small, .av2-card-details-text-body, .av2-card-list-text-body-small, .av2-card-list-text-body, .av2-input-text-right .av2-input-text-right-label, .av2-card-label, .av2-dropdown, textarea.av2-text-area, .av2-text-input, .av2-form-label, .av2-chart-card .av2-chart-amount, .av2-simple-chart-card .av2-simple-chart-subvalue, .av2-info-bar, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-subtitle, .av2-badge, .av2-link-button, .av2-button, .av2-radio-button-horizontal .p-radiobutton-label {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-1, .av2-card-details-text-title-small, .av2-card-details-text-title, .av2-card-list-text-title-small, .av2-card-list-text-title, .av2-form-field label, .av2-chart-card .av2-chart-count, .av2-chart-card .av2-chart-title, .av2-chart-card .av2-chart-card-title, .av2-map-control-point-list-row .av2-map-control-point-list-item span.point, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-title, .av2-tag-control-point-item .av2-tag-control-point-item-title, .av2-filter-badge, .av2-info-dialog-container .av2-info-dialog-message {
  font-family: "Inter-SemiBold";
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-2 {
  font-family: "Inter-SemiBold";
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-3 {
  font-family: "Inter-SemiBold";
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  text-align: center;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-4 {
  font-family: "Inter-Bold";
  font-size: 8px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-5, .av2-card-details-text-title-pdf {
  font-family: "Inter-Bold";
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-6 {
  font-family: "Inter-Bold";
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-2, .av2-table-selection-buttons-container, a.av2-form-helper, .av2-simple-chart-card .av2-simple-chart-title, .av2-tag-control-point-item .av2-tag-control-point-item-subtitle {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.26px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-3, .av2-simple-chart-card .av2-simple-chart-value {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 22px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-4, .av2-card-details-text-body-pdf {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-container {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-column-gap: 24px;
}

.p-accordion .p-accordion-content {
  padding: 24px;
}

.multiLevelAccordionTab > .p-accordion-tab > .p-toggleable-content > .p-accordion-content {
  padding: 0 !important;
}

@media only screen and (max-width: 480px) {
  .av2-container {
    grid-template-columns: repeat(4, [col-start] 1fr);
  }
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .av2-container {
    grid-template-columns: repeat(8, [col-start] 1fr);
    grid-column-gap: 18px;
  }
}
.av2-button {
  font-weight: 600;
  height: 38px;
  padding: 10px 16px;
}
.av2-button:not(.av2-close-button) {
  border-radius: 6px;
}
.av2-button-right {
  margin-right: 12px !important;
}

.av2-link-button {
  padding: 0;
  font-weight: 600 !important;
  font-size: 12px !important;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.av2-link-button i.left {
  margin-right: 6px;
}
.av2-link-button i.right {
  margin-left: 6px;
}
.av2-link-button:hover {
  text-decoration: underline;
}
.av2-link-button:disabled {
  text-decoration: none;
}

.av2-badge {
  padding: 2px 6px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: fit-content;
  user-select: none;
  white-space: nowrap;
  height: 22px;
}

.av2-filter-badge {
  letter-spacing: -0.26px;
  font-size: 12px;
  padding: 3px 3px 3px 12px;
  border-radius: 11px;
  height: 25px;
  display: flex;
  width: max-content;
  min-width: max-content;
  overflow: hidden;
  column-gap: 6px;
}
.av2-filter-badge span {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.av2-filter-badge i {
  font-size: 10px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.av2-close-button {
  border-radius: 50%;
  padding: 0;
}

.av2-rectangle-button {
  margin-right: 12px !important;
}

.av2-icon-outline {
  font-weight: 400;
  width: 12px;
  height: 14px;
  margin-left: 6px;
  margin-right: 6px;
  cursor: pointer;
}

button[type=button]:disabled {
  cursor: not-allowed;
}

.av2-overlay-panel-button i {
  height: 100%;
  margin-left: 12px;
  display: flex;
  align-self: flex-end;
  font-size: 22px;
}

.av2-overlay-panel-item-icon {
  font-weight: 400;
  width: 12px;
  height: 14px;
  margin-right: 12px;
  cursor: pointer;
}

.av2-group-button {
  cursor: pointer;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px;
  border-left: none;
}
.av2-group-button:first-child {
  border-left: 1px solid;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.av2-group-button:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.av2-card-list {
  padding: 24px;
  border-radius: 6px;
}
.av2-card-list.clickable:hover {
  cursor: pointer;
}
.av2-card-list:hover {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}
.av2-card-list-icon {
  padding-bottom: 24px;
  font-size: 24px;
}
.av2-card-list-small {
  padding: 18px;
}
.av2-card-list-small:hover {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}
.av2-card-list-small-icon {
  padding-bottom: 18px;
}
.av2-card-list.administration-create, .av2-card-list.administration-company-list, .av2-card-list.administration-tags, .av2-card-list.administration-contact {
  width: 100%;
  margin-bottom: 24px;
}
.av2-card-list.administration-create-contact, .av2-card-list.administration-object-list, .av2-card-list.administration-licenses {
  width: 100%;
  margin-bottom: 24px;
}
.av2-card-list.administration-route, .av2-card-list.administration-devices {
  width: 100%;
  margin-bottom: 24px;
}
.av2-card-list.reports-filters, .av2-card-list.reports-periodic-reports, .av2-card-list.reports-report-templates, .av2-card-list.reports-generated-reports {
  width: 100%;
  margin-bottom: 24px;
}

.av2-card-details {
  padding: 24px;
  border-radius: 6px;
}
.av2-card-details-small {
  padding: 18px;
}

.av2-card-details-pdf {
  padding: 12px;
  border-radius: 6px;
}
.av2-card-details-pdf-small {
  padding: 18px;
}

.av2-route-control-points-card {
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
}
.av2-route-control-points-card .av2-card-details-text-title {
  padding: 24px;
}
.av2-route-control-points-card-body {
  display: grid;
  padding: 24px;
  row-gap: 24px;
}

.av2-tag-control-point-item {
  border-radius: 6px;
  padding: 18px 18px 12px 18px;
  display: grid;
  grid-template-columns: 36px 1fr 1fr 16px;
  border-style: solid;
  border-width: 1px;
  column-gap: 12px;
}
.av2-tag-control-point-item .av2-tag-control-point-item-icon {
  grid-column: 1/2;
  font-size: 24px;
  display: flex;
}
.av2-tag-control-point-item .av2-tag-control-point-item-delete-icon {
  grid-column: 4/5;
  display: flex;
  grid-row: 1/2;
}
.av2-tag-control-point-item .av2-tag-control-point-item-delete-icon i {
  cursor: pointer;
  font-size: 20px;
}
.av2-tag-control-point-item .control-points-control-panel {
  display: flex;
  grid-row: 1/2;
  grid-column: 4/6;
}
.av2-tag-control-point-item .control-points-control-panel .av2-tag-control-point-item-down-arrow-icon {
  grid-column: 1/3;
  margin-right: 10px;
}
.av2-tag-control-point-item .control-points-control-panel .av2-tag-control-point-item-up-arrow-icon {
  grid-column: 2/3;
  margin-right: 5px;
}
.av2-tag-control-point-item .control-points-control-panel .av2-tag-control-point-item-delete-icon {
  grid-column: 3/3;
}
.av2-tag-control-point-item .control-points-control-panel i {
  cursor: pointer;
  font-size: 20px;
}
.av2-tag-control-point-item .av2-tag-control-point-item-title {
  grid-column: 2/4;
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
}
.av2-tag-control-point-item .av2-tag-control-point-item-subtitle {
  font-size: 12px;
  grid-column: 2/4;
  margin: 0;
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 6px;
}
.av2-tag-control-point-item .av2-tag-control-point-item-description {
  margin-top: 12px;
}

.av2-separator-1 {
  height: 1px;
}

.av2-forms-selector-icon-helper-card {
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  padding: 12px;
  display: grid;
  grid-template-columns: 36px 1fr 16px;
  grid-template-rows: 1fr 1fr;
  column-gap: 12px;
  cursor: pointer;
}
.av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-icon-container {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  grid-column: 1/2;
  grid-row: 1/3;
}
.av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-icon-container i {
  font-size: 16px;
}
.av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-title {
  font-size: 14px;
  grid-column: 2/3;
  grid-row: 1/2;
}
.av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-subtitle {
  font-size: 12px;
  grid-column: 2/3;
  grid-row: 2/3;
}
.av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-selected-icon {
  grid-column: 3/3;
  grid-row: 1/2;
}
.av2-forms-selector-icon-helper-card.pressed, .av2-forms-selector-icon-helper-card.selected {
  border-width: 2px;
}

.av2-map-control-point-list-row {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
}
.av2-map-control-point-list-row i {
  font-size: 24px;
}
.av2-map-control-point-list-row .av2-map-control-point-list-item {
  display: grid;
  grid-template-columns: 1fr 80px 80px 16px;
  column-gap: 12px;
  align-items: flex-start;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  padding: 12px;
}
.av2-map-control-point-list-row .av2-map-control-point-list-item span.point {
  font-size: 14px;
  grid-column: 1/2;
}
.av2-map-control-point-list-row .av2-map-control-point-list-item i {
  grid-column: 4/5;
  font-size: 16px;
  cursor: pointer;
}

.card-size-height_s {
  height: auto;
}
.card-size-height_s2 {
  height: auto;
}
.card-size-height_m {
  height: auto;
}
.card-size-height_l {
  height: auto;
}
.card-size-height_xl {
  height: auto;
}

@media only screen and (max-width: 480px) {
  .av2-card-list {
    padding: 0;
    border: 0;
    display: grid;
    grid-template-columns: 48px 2fr 15px;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 2px;
  }
  .av2-card-list.clickable:hover {
    cursor: pointer;
  }
  .av2-card-list:hover {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
  }
  .av2-card-list-icon {
    grid-column: 1/2;
    grid-row: 1/3;
    padding-bottom: 0;
    font-size: 24px;
    width: 48px;
    height: 48px;
    place-self: center;
    align-items: center;
    text-align: center;
    padding-top: 12px;
  }
  .av2-card-list.administration-create-contact, .av2-card-list.administration-object-list, .av2-card-list.administration-licenses, .av2-card-list.administration-devices, .av2-card-list.administration-route, .av2-card-list.administration-contact, .av2-card-list.administration-create, .av2-card-list.administration-company-list, .av2-card-list.administration-tags, .av2-card-list.administration-employees, .av2-card-list.reports-filters, .av2-card-list.reports-periodic-reports, .av2-card-list.reports-report-templates, .av2-card-list.reports-generated-reports {
    width: 100%;
    grid-column: 1/10;
    margin-bottom: 24px;
  }
  .card-size-height_s {
    height: auto;
  }
}
.av2-info-bar {
  width: 100%;
  padding: 6px 12px;
  border-radius: 6px;
  margin-bottom: 18px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.53px;
  display: grid;
  grid-template-columns: 18px 1fr;
  align-items: center;
  column-gap: 12px;
}
.av2-info-bar .icon {
  margin-right: 10px;
}

.av2-simple-chart-card {
  height: 100px;
  padding: 18px;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
}
.av2-simple-chart-card .av2-simple-chart-title {
  margin-bottom: 6px;
}
.av2-simple-chart-card .av2-simple-chart-value-row {
  display: flex;
  align-items: baseline;
  column-gap: 6px;
}
.av2-simple-chart-card .p-progressbar {
  margin-top: 12px;
  height: 4px;
}

.av2-chart-card {
  padding: 18px;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  height: 100%;
}
.av2-chart-card .av2-chart-card-title {
  margin-bottom: 24px;
}
.av2-chart-card .av2-chart-subtitle-row {
  display: flex;
  margin-top: 6px;
  column-gap: 6px;
}

.graphical-report-spinner {
  z-index: 1000000;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-device-form-spinner {
  z-index: 1000000;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.av2-container {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-column-gap: 24px;
}

.p-accordion .p-accordion-content {
  padding: 24px;
}

.multiLevelAccordionTab > .p-accordion-tab > .p-toggleable-content > .p-accordion-content {
  padding: 0 !important;
}

@media only screen and (max-width: 480px) {
  .av2-container {
    grid-template-columns: repeat(4, [col-start] 1fr);
  }
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .av2-container {
    grid-template-columns: repeat(8, [col-start] 1fr);
    grid-column-gap: 18px;
  }
}
.av2-form-field {
  display: flex;
  flex-flow: wrap column;
}
.av2-form-field label {
  font-size: 12px;
  margin-bottom: 6px;
}
.av2-form-field-filter {
  position: relative;
}
.av2-form-field-filter i.placeholder-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  right: 12px;
}

.av2-form-label {
  font-size: 14px;
  margin: 0;
  margin-bottom: 2px;
  margin-left: 6px;
}

.av2-text-input {
  padding: 10px 12px;
  border-radius: 6px;
  height: 38px;
}
.av2-text-input-filter {
  width: 100%;
}

textarea.av2-text-area {
  padding: 10px 12px;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
}
textarea.av2-text-area:focus {
  outline: none !important;
}

a.av2-form-helper {
  cursor: pointer;
}

.av2-dropdown {
  padding: 10px 12px;
  border-radius: 6px;
  width: 100%;
}

.av2-card-label {
  font-size: 14px;
  width: 148px;
  height: auto;
  margin: 0;
}

.av2-card-value {
  font-size: 14px;
  min-width: 148px;
  height: auto;
  margin: 0 12px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.av2-input-text-right {
  position: relative;
  display: inline-block;
  height: 38px;
}
.av2-input-text-right input[type=number]::-webkit-inner-spin-button,
.av2-input-text-right input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.av2-input-text-right input[type=number] {
  -moz-appearance: textfield;
}
.av2-input-text-right .av2-input-text-right-label {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  right: 0.75rem;
}

av2-calendar .p-calendar {
  width: 100%;
}

input[type=checkbox] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  cursor: pointer;
}
input[type=checkbox]:before {
  font-size: 8px;
  font-family: "primeicons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  content: "\e909";
}

.av2-text-small-1 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-2 {
  font-family: "Inter-Regular";
  font-size: 11px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-3 {
  font-family: "Inter-Regular";
  font-size: 8px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-1, .av2-card-details-text-body-small, .av2-card-details-text-body, .av2-card-list-text-body-small, .av2-card-list-text-body, .av2-radio-button-horizontal .p-radiobutton-label, .av2-button, .av2-link-button, .av2-badge, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-subtitle, .av2-info-bar, .av2-simple-chart-card .av2-simple-chart-subvalue, .av2-chart-card .av2-chart-amount, .av2-form-label, .av2-text-input, textarea.av2-text-area, .av2-dropdown, .av2-card-label, .av2-input-text-right .av2-input-text-right-label {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-1, .av2-card-details-text-title-small, .av2-card-details-text-title, .av2-card-list-text-title-small, .av2-card-list-text-title, .av2-info-dialog-container .av2-info-dialog-message, .av2-filter-badge, .av2-tag-control-point-item .av2-tag-control-point-item-title, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-title, .av2-map-control-point-list-row .av2-map-control-point-list-item span.point, .av2-chart-card .av2-chart-card-title, .av2-chart-card .av2-chart-title, .av2-chart-card .av2-chart-count, .av2-form-field label {
  font-family: "Inter-SemiBold";
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-2 {
  font-family: "Inter-SemiBold";
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-3 {
  font-family: "Inter-SemiBold";
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  text-align: center;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-4 {
  font-family: "Inter-Bold";
  font-size: 8px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-5, .av2-card-details-text-title-pdf {
  font-family: "Inter-Bold";
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-6 {
  font-family: "Inter-Bold";
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-2, .av2-table-selection-buttons-container, .av2-tag-control-point-item .av2-tag-control-point-item-subtitle, .av2-simple-chart-card .av2-simple-chart-title, a.av2-form-helper {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.26px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-3, .av2-simple-chart-card .av2-simple-chart-value {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 22px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-4, .av2-card-details-text-body-pdf {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-header-action-buttons-container {
  width: auto;
  display: flex;
  grid-column: 9/13;
  column-gap: 12px;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 18px;
}

.av2-table-selection-buttons-container {
  margin-top: 24px;
  margin-bottom: 18px;
  display: flex;
  grid-column: 1/13;
  height: 20px;
  align-items: center;
  justify-content: flex-start;
  column-gap: 12px;
}

.av2-table-link-column {
  text-decoration: none;
  font-weight: 600;
}
.av2-table-link-column:hover {
  text-decoration: underline;
}

.av2-table-sort-header {
  cursor: pointer;
  min-width: 100px;
}

.checkbox-and-dropdown-action-menu {
  width: 50px !important;
}

@media only screen and (max-width: 480px) {
  .av2-header-action-buttons-container {
    grid-column: 1/5;
    justify-content: flex-start;
  }
  .av2-table-selection-buttons-container {
    grid-column: 1/5;
  }
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .av2-header-action-buttons-container {
    grid-column: 5/9;
  }
  .av2-table-selection-buttons-container {
    grid-column: 1/9;
  }
}
.av2-section {
  width: 100%;
  height: 30px;
  margin: 0 0 14px;
  padding: 0 0 12px;
}

.av2-text-small-1 {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-2 {
  font-family: "Inter-Regular";
  font-size: 11px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-small-3 {
  font-family: "Inter-Regular";
  font-size: 8px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-1, .av2-card-details-text-body-small, .av2-card-details-text-body, .av2-card-list-text-body-small, .av2-card-list-text-body, .av2-radio-button-horizontal .p-radiobutton-label, .av2-button, .av2-link-button, .av2-badge, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-subtitle, .av2-info-bar, .av2-simple-chart-card .av2-simple-chart-subvalue, .av2-chart-card .av2-chart-amount, .av2-form-label, .av2-text-input, textarea.av2-text-area, .av2-dropdown, .av2-card-label, .av2-input-text-right .av2-input-text-right-label {
  font-family: "Inter-Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-1, .av2-card-details-text-title-small, .av2-card-details-text-title, .av2-card-list-text-title-small, .av2-card-list-text-title, .av2-info-dialog-container .av2-info-dialog-message, .av2-filter-badge, .av2-tag-control-point-item .av2-tag-control-point-item-title, .av2-forms-selector-icon-helper-card .av2-forms-selector-icon-helper-card-title, .av2-map-control-point-list-row .av2-map-control-point-list-item span.point, .av2-chart-card .av2-chart-card-title, .av2-chart-card .av2-chart-title, .av2-chart-card .av2-chart-count, .av2-form-field label {
  font-family: "Inter-SemiBold";
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.53px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-2 {
  font-family: "Inter-SemiBold";
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-3 {
  font-family: "Inter-SemiBold";
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  text-align: center;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-4 {
  font-family: "Inter-Bold";
  font-size: 8px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-5, .av2-card-details-text-title-pdf {
  font-family: "Inter-Bold";
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-bold-6 {
  font-family: "Inter-Bold";
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.83px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-2, .av2-tag-control-point-item .av2-tag-control-point-item-subtitle, .av2-simple-chart-card .av2-simple-chart-title, a.av2-form-helper, .av2-table-selection-buttons-container {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.26px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-3, .av2-simple-chart-card .av2-simple-chart-value {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 22px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-text-normal-4, .av2-card-details-text-body-pdf {
  font-family: "Inter-Regular";
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.88px;
  font-size: 12px;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.av2-container {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-column-gap: 24px;
}

.p-accordion .p-accordion-content {
  padding: 24px;
}

.multiLevelAccordionTab > .p-accordion-tab > .p-toggleable-content > .p-accordion-content {
  padding: 0 !important;
}

@media only screen and (max-width: 480px) {
  .av2-container {
    grid-template-columns: repeat(4, [col-start] 1fr);
  }
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .av2-container {
    grid-template-columns: repeat(8, [col-start] 1fr);
    grid-column-gap: 18px;
  }
}
.av2-card-list-text-title {
  padding-bottom: 12px;
  margin: 0;
  font-size: 18px;
}

.av2-card-list-text-body {
  padding-bottom: 12px;
  margin: 0;
}

.av2-card-list-text-title-small {
  padding-bottom: 6px;
  margin: 0;
}

.av2-card-list-text-body-small {
  padding-bottom: 6px;
  margin: 0;
}

.av2-card-details-text-title {
  padding-bottom: 18px;
  margin: 0;
}

.av2-card-details-text-title-pdf {
  padding-bottom: 12px;
  margin: 0;
}

.av2-card-details-text-body {
  margin: 0;
}

.av2-card-details-text-body-pdf {
  margin: 0;
}

.av2-card-details-text-title-small {
  padding-bottom: 12px;
  margin: 0;
}

.av2-card-details-text-body-small {
  margin: 0;
}

.av2-page-header {
  grid-column: 1/13;
  margin-bottom: 36px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: 480px) {
  .av2-card-list-text-title {
    align-self: end;
    font-size: 14px;
    grid-column: 2/3;
    grid-template-rows: 1/2;
    margin: 0;
    padding: 0;
  }
  .av2-card-list-text-body {
    grid-column: 2/3;
    grid-template-rows: 2/3;
    margin: 0;
    font-size: 12px;
    padding: 0;
  }
  .av2-page-header {
    grid-column: 1/5;
    margin-bottom: 0;
    margin-top: 0;
  }
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  .av2-page-header {
    grid-column: 1/10;
    margin-bottom: 0;
    margin-top: 0;
  }
}
.av2-icon-svg-24 svg {
  width: 24px;
  height: 24px;
}

app-paginator {
  grid-column: 1/13;
}
app-paginator .av2-paginator-container {
  display: grid;
  align-items: center;
  grid-template-columns: auto repeat(11, 1fr);
}
app-paginator .av2-paginator-container .paginator-sites {
  grid-column: 1/2;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 6px;
}
app-paginator .av2-paginator-container .paginator-go-to {
  grid-column: 2/7;
  align-items: baseline;
  flex-wrap: wrap;
  display: flex;
  column-gap: 6px;
}
app-paginator .av2-paginator-container .dropdown-container {
  grid-column: 10/13;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 6px;
  justify-self: flex-end;
}
app-paginator .av2-paginator-container .p-paginator {
  padding-left: 0;
}
app-paginator .av2-paginator-container .p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 1.357rem;
  height: 1.357rem;
}
app-paginator .av2-paginator-container .p-paginator .pi {
  font-size: 14px;
}
app-paginator .av2-paginator-container p-inputNumber {
  display: contents;
}
app-paginator .av2-paginator-container p-inputNumber input {
  height: 24px !important;
  width: 34px !important;
  padding: 0 !important;
  text-align: center;
}
app-paginator .av2-paginator-container p-dropdown .p-dropdown.p-component {
  padding: 3px 4px 4px 5px;
}

@media only screen and (max-width: 480px) {
  app-paginator {
    grid-column: 1/5;
  }
  app-paginator .av2-paginator-container {
    grid-column: 1/5;
    grid-template-columns: repeat(4, 1fr);
  }
  app-paginator .av2-paginator-container .paginator-sites {
    grid-column: 1/5;
    justify-content: center;
  }
  app-paginator .av2-paginator-container .paginator-go-to {
    grid-column: 1/3;
  }
  app-paginator .av2-paginator-container .dropdown-container {
    grid-column: 3/5;
  }
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  app-paginator {
    grid-column: 1/9;
  }
  app-paginator .av2-paginator-container .paginator-go-to {
    grid-column: 2/6;
  }
  app-paginator .av2-paginator-container .dropdown-container {
    grid-column: 10/13;
  }
}
p-tabMenu[styleclass=full-grid] {
  grid-column: 1/13;
}

@media only screen and (max-width: 480px) {
  p-tabMenu[styleclass=full-grid] {
    grid-column: 1/5;
  }
}
@media only screen and (min-width: 480px) and (max-width: 1024px) {
  p-tabMenu[styleclass=full-grid] {
    grid-column: 1/9;
  }
}