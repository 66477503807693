@import "../styles/fonts";
@import "../styles/layout";

.av2-info-dialog-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 232px;
  position: relative;
  padding: 0;
  .av2-info-dialog-icon {
    width: 64px;
    height: 64px;
  }

  .av2-info-dialog-message {
    @extend .av2-text-bold-1;
    margin-top: $spacing-l;
    font-size: 18px;
    letter-spacing: -0.72px;
  }
}

.av2-info-dialog-footer {
  margin-top: $spacing-m;
  width: 232px;
  display: flex;
  flex-flow: column;
  row-gap: $spacing-xs;
}
