@import "../fonts";
@import "../layout";

$buttonRadius: 4px;
$buttonPadding: 10px 16px;
$buttonHeight: 38px;

.av2-radio-button-horizontal {
    .p-radiobutton {
        display: none;
    }

    .p-radiobutton-label {
        @extend .av2-text-normal-1;
        font-weight: 600;
        height: $buttonHeight;
        padding: $buttonPadding;
        border-radius: $buttonRadius;
        text-align: center;
        margin: 0;
        width: 100%;
        cursor: pointer;
    }
}
