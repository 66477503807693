$sectionHeight: 30px;
$sectionWidth: 100%;
$sectionMargin: 0 0 14px;
$sectionPadding: 0 0 12px;

.av2-section {
  width: $sectionWidth;
  height: $sectionHeight;
  margin: $sectionMargin;
  padding: $sectionPadding;
}
