@import "../fonts";
@import "../layout";
$cardTextMargin: 0;
$cardFontSize: 18px;
$cardFontSizeMobile: 14px;
$cardFontSizeMobileBody: 12px;

.av2-card-list-text-title {
  @extend .av2-text-bold-1;
  padding-bottom: $spacing-xs;
  margin: $cardTextMargin;
  font-size: $cardFontSize;
}

.av2-card-list-text-body {
  @extend .av2-text-normal-1;
  padding-bottom: $spacing-xs;
  margin: $cardTextMargin;
}
.av2-card-list-text-title-small {
  @extend .av2-text-bold-1;
  padding-bottom: $spacing-xxs;
  margin: $cardTextMargin;
}
.av2-card-list-text-body-small {
  @extend .av2-text-normal-1;
  padding-bottom: $spacing-xxs;
  margin: $cardTextMargin;
}

.av2-card-details-text-title {
  @extend .av2-text-bold-1;
  padding-bottom: $spacing-s;
  margin: $cardTextMargin;
}
.av2-card-details-text-title-pdf {
  @extend .av2-text-bold-5;
  padding-bottom: $spacing-xs;
  margin: $cardTextMargin;
}
.av2-card-details-text-body {
  @extend .av2-text-normal-1;
  margin: $cardTextMargin;
}
.av2-card-details-text-body-pdf {
  @extend .av2-text-normal-4;
  margin: $cardTextMargin;
}
.av2-card-details-text-title-small {
  @extend .av2-text-bold-1;
  padding-bottom: $spacing-xs;
  margin: $cardTextMargin;
}
.av2-card-details-text-body-small {
  @extend .av2-text-normal-1;
  margin: $cardTextMargin;
}

.av2-page-header {
  grid-column: 1/13;
  margin-bottom: $spacing-l;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: $mobileResolution) {
  .av2-card-list-text-title {
    @extend .av2-text-bold-1;
    align-self: end;
    font-size: $cardFontSizeMobile;
    grid-column: 2/3;
    grid-template-rows: 1/2;
    margin: 0;
    padding: 0;

  }

  .av2-card-list-text-body {
    @extend .av2-text-normal-1;
    grid-column: 2/3;
    grid-template-rows: 2/3;
    margin: 0;
    font-size: $cardFontSizeMobileBody;
    padding: 0;
  }
  .av2-page-header {
    grid-column: 1/5;
    margin-bottom: 0;
    margin-top: 0;
  }

}

@media only screen and (min-width: $mobileResolution) and (max-width: $tabletResolution) {
  .av2-page-header {
    grid-column: 1/10;
    margin-bottom: 0;
    margin-top: 0;
  }
}
