* {
  box-sizing: border-box;
}

body, html {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}


