@font-face {
  font-family: "Inter-Black";
  src: url("./inter/Inter-Black.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("./inter/Inter-Bold.ttf");
}

@font-face {
  font-family: "Inter-ExtraBold";
  src: url("./inter/Inter-ExtraBold.ttf");
}

@font-face {
  font-family: "Inter-ExtraLight";
  src: url("./inter/Inter-ExtraLight.ttf");
}

@font-face {
  font-family: "Inter-Light";
  src: url("./inter/Inter-Light.ttf");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("./inter/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("./inter/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("./inter/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter-Thin";
  src: url("./inter/Inter-Thin.ttf");
}
