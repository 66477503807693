@import "../fonts";

.av2-header-action-buttons-container {
  width: auto;
  display: flex;
  grid-column: 9/13;
  column-gap: $spacing-xs;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: $spacing-s;
}


.av2-table-selection-buttons-container {
  @extend .av2-text-normal-2;
  margin-top: $spacing-m;
  margin-bottom: $spacing-s;
  display: flex;
  grid-column: 1/13;
  height: 20px;
  align-items: center;
  justify-content: flex-start;
  column-gap: $spacing-xs;
}

.av2-table-link-column {
  text-decoration: none;
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
}

.av2-table-sort-header {
  cursor: pointer;
  min-width: 100px;
}


.checkbox-and-dropdown-action-menu {
  width: 50px !important;
}

@media only screen and (max-width: $mobileResolution) {
  .av2-header-action-buttons-container {
    grid-column: 1/5;
    justify-content: flex-start;
  }

  .av2-table-selection-buttons-container {
    grid-column: 1/5;
  }
}

@media only screen and (min-width: $mobileResolution) and (max-width: $tabletResolution) {
  .av2-header-action-buttons-container {
    grid-column: 5/9;
  }

  .av2-table-selection-buttons-container {
    grid-column: 1/9;
  }
}
