//ACTIVE
$cardBorderRadius: 6px;

//HOVER
$cardHoverBoxShadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
//ICON
$cardIconSize: 24px;
$cardIconSizeMobile: 48px;

.av2-card-list {
  padding: $spacing-m;
  border-radius: $cardBorderRadius;
  &.clickable:hover {
    cursor: pointer;
  }
  &:hover {
    box-shadow: $cardHoverBoxShadow;
  }
  &-icon {
    padding-bottom: $spacing-m;
    font-size: $cardIconSize;
  }
  &-small {
    padding: $spacing-s;
    &:hover {
      box-shadow: $cardHoverBoxShadow;
    }
    &-icon {
      padding-bottom: $spacing-s;
    }
  }

  &.administration-create,
  &.administration-company-list,
  &.administration-tags,
  &.administration-contact {
    width: 100%;
    margin-bottom: $spacing-m;
  }

  &.administration-create-contact,
  &.administration-object-list,
  &.administration-licenses {
    width: 100%;
    margin-bottom: $spacing-m;
  }

  &.administration-route,
  &.administration-devices {
    width: 100%;
    margin-bottom: $spacing-m;
  }

  &.reports-filters,
  &.reports-periodic-reports,
  &.reports-report-templates,
  &.reports-generated-reports {
    width: 100%;
    margin-bottom: $spacing-m;
  }
}

.av2-card-details {
  padding: $spacing-m;
  border-radius: $cardBorderRadius;
  &-small {
    padding: $spacing-s;
  }
}

.av2-card-details-pdf {
  padding: $spacing-xs;
  border-radius: $cardBorderRadius;
  &-small {
    padding: $spacing-s;
  }
}

.av2-route-control-points-card {
  border-radius: $cardBorderRadius;
  border-style: solid;
  border-width: 1px;
  .av2-card-details-text-title {
    padding: $spacing-m;
  }
  &-body {
    display: grid;
    padding: $spacing-m;
    row-gap: $spacing-m;
  }
}

.av2-tag-control-point-item {
  border-radius: $cardBorderRadius;
  padding: $spacing-s $spacing-s $spacing-xs $spacing-s;
  display: grid;
  grid-template-columns: 36px 1fr 1fr 16px;
  border-style: solid;
  border-width: 1px;
  column-gap: $spacing-xs;
  .av2-tag-control-point-item-icon {
    grid-column: 1/2;
    font-size: $cardIconSize;
    display: flex;
  }
  .av2-tag-control-point-item-delete-icon {
    grid-column: 4/5;
    display: flex;
    grid-row: 1/2;
    i {
      cursor: pointer;
      font-size: 20px;
    }
  }
  .control-points-control-panel {
    display: flex;
    grid-row: 1/2;
    grid-column: 4/6;
    .av2-tag-control-point-item-down-arrow-icon {
      grid-column: 1/3;
      margin-right: 10px;
    }
    .av2-tag-control-point-item-up-arrow-icon {
      grid-column: 2/3;
      margin-right: 5px;
    }
    .av2-tag-control-point-item-delete-icon {
      grid-column: 3/3;
    }
    i {
      cursor: pointer;
      font-size: 20px;
    }
  }
  .av2-tag-control-point-item-title {
    @extend .av2-text-bold-1;
    grid-column: 2/4;
    margin: 0;
    margin-bottom: 8px;
    font-size: 16px;
  }
  .av2-tag-control-point-item-subtitle {
    @extend .av2-text-normal-2;
    font-size: 12px;
    grid-column: 2/4;
    margin: 0;
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: $spacing-xxs;
  }

  .av2-tag-control-point-item-description {
    margin-top: $spacing-xs;
  }
}

.av2-separator-1 {
  height: 1px;
}

.av2-forms-selector-icon-helper-card {
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  padding: $spacing-xs;
  display: grid;
  grid-template-columns: 36px 1fr 16px;
  grid-template-rows: 1fr 1fr;
  column-gap: $spacing-xs;
  cursor: pointer;
  .av2-forms-selector-icon-helper-card-icon-container {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    grid-column: 1/2;
    grid-row: 1/3;
    i {
      font-size: 16px;
    }
  }
  .av2-forms-selector-icon-helper-card-title {
    @extend .av2-text-bold-1;
    font-size: 14px;
    grid-column: 2/3;
    grid-row: 1/2;
  }
  .av2-forms-selector-icon-helper-card-subtitle {
    @extend .av2-text-normal-1;
    font-size: 12px;
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .av2-forms-selector-icon-helper-card-selected-icon {
    grid-column: 3/3;
    grid-row: 1/2;
  }

  &.pressed,
  &.selected {
    border-width: 2px;
  }
}

.av2-map-control-point-list-row {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  i {
    font-size: $cardIconSize;
  }
  .av2-map-control-point-list-item {
    display: grid;
    grid-template-columns: 1fr 80px 80px 16px;
    column-gap: $spacing-xs;
    align-items: flex-start;
    border-radius: $cardBorderRadius;
    border-width: 1px;
    border-style: solid;
    padding: $spacing-xs;
    span.point {
      @extend .av2-text-bold-1;
      font-size: 14px;
      grid-column: 1/2;
    }
    i {
      grid-column: 4/5;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.card-size {
  &-height_s {
    height: auto;
  }

  &-height_s2 {
    height: auto;
  }

  &-height_m {
    height: auto;
  }

  &-height_l {
    height: auto;
  }

  &-height_xl {
    height: auto;
  }
}

@media only screen and (max-width: $mobileResolution) {
  .av2-card-list {
    padding: 0;
    border: 0;
    display: grid;
    grid-template-columns: 48px 2fr 15px;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: $spacing-xs;
    grid-row-gap: 2px;
    &.clickable:hover {
      cursor: pointer;
    }
    &:hover {
      box-shadow: $cardHoverBoxShadow;
    }
    &-icon {
      grid-column: 1/2;
      grid-row: 1/3;
      padding-bottom: 0;
      font-size: $cardIconSize;
      width: $cardIconSizeMobile;
      height: $cardIconSizeMobile;
      place-self: center;
      align-items: center;
      text-align: center;
      padding-top: 12px;
    }
    &.administration-create-contact,
    &.administration-object-list,
    &.administration-licenses,
    &.administration-devices,
    &.administration-route,
    &.administration-contact,
    &.administration-create,
    &.administration-company-list,
    &.administration-tags,
    &.administration-employees,
    &.reports-filters,
    &.reports-periodic-reports,
    &.reports-report-templates,
    &.reports-generated-reports {
      width: 100%;
      grid-column: 1/10;
      margin-bottom: $spacing-m;
    }
  }
  .card-size {
    &-height_s {
      height: auto;
    }
  }
}

.av2-info-bar {
  @extend .av2-text-normal-1;
  width: 100%;
  padding: $spacing-xxs $spacing-xs;
  border-radius: 6px;
  margin-bottom: $spacing-s;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: -0.53px;
  display: grid;
  grid-template-columns: 18px 1fr;
  align-items: center;
  column-gap: $spacing-xs;
  .icon {
    margin-right: 10px;
  }
}

.av2-simple-chart-card {
  height: 100px;
  padding: $spacing-s;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  .av2-simple-chart-title {
    @extend .av2-text-normal-2;
    margin-bottom: $spacing-xxs;
  }
  .av2-simple-chart-value-row {
    display: flex;
    align-items: baseline;
    column-gap: $spacing-xxs;
  }
  .av2-simple-chart-value {
    @extend .av2-text-normal-3;
  }
  .av2-simple-chart-subvalue {
    @extend .av2-text-normal-1;
  }
  .p-progressbar {
    margin-top: $spacing-xs;
    height: 4px;
  }
}

.av2-chart-card {
  padding: $spacing-s;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  height: 100%;
  .av2-chart-card-title {
    @extend .av2-text-bold-1;
    margin-bottom: $spacing-m;
  }
  .av2-chart-title {
    @extend .av2-text-bold-1;
  }
  .av2-chart-count {
    @extend .av2-text-bold-1;
  }
  .av2-chart-amount {
    @extend .av2-text-normal-1;
  }
  .av2-chart-subtitle-row {
    display: flex;
    margin-top: $spacing-xxs;
    column-gap: $spacing-xxs;
  }
}

.graphical-report-spinner {
  z-index: 1000000;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-device-form-spinner {
  z-index: 1000000;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
