p-tabMenu[styleclass="full-grid"]{
  grid-column: 1/13;
}
@media only screen and (max-width: $mobileResolution) {
  p-tabMenu[styleclass="full-grid"]{
    grid-column: 1/5;
  }
}

@media only screen and (min-width: $mobileResolution) and (max-width: $tabletResolution) {
  p-tabMenu[styleclass="full-grid"]{
    grid-column: 1/9;
  }
}
