app-paginator {
  grid-column: 1/13;

  .av2-paginator-container {
    display: grid;
    align-items: center;
    // justify-content: space-between;
    grid-template-columns: auto repeat(11, 1fr);

    .paginator-sites {
      grid-column: 1/2;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 6px;
    }

    .paginator-go-to {
      grid-column: 2/7;
      align-items: baseline;
      flex-wrap: wrap;
      display: flex;
      column-gap: 6px;
    }
    .dropdown-container {
      grid-column: 10/13;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 6px;
      justify-self: flex-end;
    }

    .p-paginator {
      padding-left: 0;
      

      .p-paginator-pages .p-paginator-page {
        min-width: 1.357rem;
        height: 1.357rem;
      }

      .pi {
        font-size: 14px;
      }
    }

    p-inputNumber {
      display: contents;
      input {
        height: 24px !important;
        width: 34px !important;
        padding: 0 !important;
        text-align: center;
      }
    }

    p-dropdown {
      .p-dropdown {
        &.p-component {
          padding: 3px 4px 4px 5px;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobileResolution) {
  app-paginator {
    grid-column: 1/5;
    .av2-paginator-container {
      grid-column: 1/5;
      grid-template-columns: repeat(4, 1fr);
      .paginator-sites {
        grid-column: 1/5;
        justify-content: center;
      }

      .paginator-go-to {
        grid-column: 1/3;
      }
      .dropdown-container {
        grid-column: 3/5;
      }
    }
  }
}

@media only screen and (min-width: $mobileResolution) and (max-width: $tabletResolution) {
  app-paginator {
    grid-column: 1/9;
    .av2-paginator-container {
      .paginator-go-to {
        grid-column: 2/6;
      }
      .dropdown-container {
        grid-column: 10/13;
      }
    }
  }
}
